.hover-effect:hover {
    background-color: #2095A1 !important; /* Change background color on hover */
    color: #fff; /* Change text color on hover */
}
.fade-transition {
    transition: opacity 0.5s ease;
    opacity: 1;
}

.fade-transition.hidden {
    opacity: 0;
}

