.bg-orange-500{
    background-color: #2095A1;
}

.bg-orange-500:hover{
    background-color: #F8852B;
}

.margintop_l{
    margin-top: 100px;
}

.bg-WA{
    background-color: #32D24E;
}
.bg-WA:hover{
    background-color: #F8852B;
}

.fade-transition {
    transition: opacity 0.5s ease, transform 0.5s ease;
    opacity: 1;
    transform: translateX(0);
}

.fade-transition.hidden {
    opacity: 0;
    transform: translateX(100%);
}

/* Style for large devices */
@media only screen and (min-width: 1024px) {
    .solar, .led {
        display: inline-block;
        position: fixed;
        margin: 10px;
        top: 50%;
        left: 20px;
        transform: translateY(-50%);
        padding: 10px 20px;
        background-color: transparent;
        color: black;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        z-index: 1000; /* Ensure buttons appear above other content */
        text-decoration: none; /* Remove underline */
        font-size: 16px; /* Adjust font size */
        font-weight: bold; /* Adjust font weight */
        text-transform: uppercase; /* Convert text to uppercase */
        transition: border-color 0.3s ease; /* Smooth transition for border color */
        position: relative; /* Make sure pseudo-element is positioned relative to the button */
    }

    .solar::after, .led::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: -3px; /* Adjust the distance of the line from the button */
        width: 100%;
        height: 3px;
        background-color: black; /* Color of the line */
        transition: bottom 0.3s ease; /* Smooth transition for line position */
    }

    .led {
        top: calc(50% + 60px); /* Adjust vertical position for LED button */
    }

    /* Hover effect for the buttons */
    .solar:hover, .led:hover {
        border-color: black; /* Change border color on hover */
    }

    /* Hover effect for the line */
    .solar:hover::after, .led:hover::after {
        bottom: -6px; /* Move the line up on hover */
    }

    /* Icon style */
    .solar::before, .led::before {
        content: ""; /* Unicode for a sun icon (you can replace it with your desired icon) */
        margin-right: 5px; /* Adjust spacing between icon and text */
    }
    .solar:hover, .led:hover {
        border-color: black; /* Change border color on hover */
        color: #2095A1; /* Change text color on hover */
    }

    /* Active effect for the buttons */
    .solar:active, .led:active {
        background-color: #2095A1; /* Change background color on click */
        color: white; /* Change text color on click */
    }

    /* Hover effect for the line */
    .solar:hover::after, .led:hover::after {
        bottom: -6px; /* Move the line up on hover */
    }

    /* Active effect for the line */
    .solar:active::after, .led:active::after {
        bottom: -9px; /* Move the line further up on click */
    }

    /* Icon styles (for example, using FontAwesome icons) */
    .solar::before, .led::before {
        font-family: 'Font Awesome'; /* Assuming you're using FontAwesome */
        font-weight: 900; /* Adjust icon weight */
    }

    /* Icon for Solar button */
    .solar::before {
        content: "\f185"; /* Unicode for sun icon in FontAwesome */
    }

    /* Icon for LED button */
    .led::before {
        content: "\f0eb"; /* Unicode for lightbulb icon in FontAwesome */
    }
}

/* Hide buttons on smaller devices */
@media only screen and (max-width: 1023px) {
    .solar, .led {
        display: none;
    }
}




