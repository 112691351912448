@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap');

.newsContainer{

}

.newsContainer{
  display: flex;
  justify-content: center; /* Center align horizontally */
  align-items: center; /* Center align vertically */
}

.newsCard {
    display: flex;
    width: 100%;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    width: 90%;
    margin: 25px;
    border-radius: 5px;

  }

  .newsText {
    padding: 0 0 0 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .author {
    font-size: 12px;
    font-weight: 300;
    line-height: 22px;
    color: #808290;
  }

  .lowerNewsText {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    padding: 10px 0;
  }

  .readmore {
    font-size: 12px;
    font-weight: 400;
    padding-top: 10px;
  }

  .source {
    text-decoration: none;
    color: black;
  }

  .newsImage {
    object-fit: cover;
    width: 320px;
    height: 268px;
  }
  .title {
    font-size: 22px;
    line-height: 27px;
    font-family: "Roboto", sans-serif;
  }

  .muted {
    font-weight: 100;
  }

  .description {
    font-size: 16px;
    line-height: 22px;
    color: #44444d;
  }

  @media (max-width: 1100px) and (min-width: 800px) {
    .newsCard {
      flex-direction: column;
      width: 70%;
    }
    .newsImage {
      width: 100%;
      height: 100%;
      padding-bottom: 20px;
    }
  }

  @media (max-width: 800px) {
    .newsCard {
      flex-direction: column;
      width: 95%;
    }
    .newsImage {
      width: 100%;
      height: 100%;
      padding-bottom: 20px;
    }
  }

  .loadMore {
    cursor: pointer;
    background-color: white;
    border: none;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    padding: 7px 25px;
    font-size: 13px;
    margin-top: -20px;
  }